import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { SEO } from '../rv-router.types'

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) { }

  updateTags(seo?: SEO) {
    seo?.title && this.updateTitle(seo.title)
    seo?.description && this.updateDescription(seo.description)
  }

  updateTitle(title: string) {
    this.title.setTitle(title)
    this.meta.updateTag({ property: `title`, content: title })
    this.meta.updateTag({ name: `title`, content: title })
    this.meta.updateTag({ property: `og:title`, content: title })
    this.meta.updateTag({ name: `twitter:title`, content: title })
  }

  updateDescription(description: string) {
    this.meta.updateTag({
      property: `og:description`,
      content: description
    })
    this.meta.updateTag({ property: `description`, content: description })
    this.meta.updateTag({ name: `description`, content: description })
    this.meta.updateTag({
      name: `twitter:description`,
      content: description
    })
  }

  updateImage(image: string) {
    this.meta.updateTag({ name: `twitter:image`, content: image })
    this.meta.updateTag({ property: `og:image`, content: image })
  }
}
